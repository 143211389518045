import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const addProduct = createAsyncThunk(
  "add-product/addProduct",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post("/products/add_product", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.status_code === 0) {
        return response.data.productDetails;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.response?.message) || "An error occured";
    }
  }
);

export const fetchMerchantProducts = createAsyncThunk(
  "product/fetchMerchantProducts",
  async (merchantId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get(`/merchant_products/${merchantId}`, {
        withCredentials: true,
      });
      if (data.status_code === 0) {
        return fulfillWithValue(data.products);
      } else {
        return fulfillWithValue([]);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "product/fetchProductById",
  async (productId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.get(`/products/product_details/${productId}`, {
        withCredentials: true,
      });
      const { data } = response;
      if (!data.productDetails || !data.productDetails.product_id) {
        throw new Error("Product ID not found in response data");
      }
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async ({ productId, productData }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.post(
        `/products/update_product/${productId}`,
        productData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/products/delete_product/${productId}`);
      if (response.data.status_code === 0) {
        return { productId, message: response.data.message };
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const productReducer = createSlice({
  name: "product",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    products: [],
    totalProduct: 0,
    status: "idle",
    error: null,
    updateSuccessMessage: "",
    updateErrorMessage: "",
    deleteSuccessMessage: "",
    deleteErrorMessage: "",
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.updateSuccessMessage = "";
      state.updateErrorMessage = "";
      state.deleteSuccessMessage = "";
      state.deleteErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(addProduct.pending, (state) => {
        state.loader = true;
      })
      .addCase(addProduct.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload;
      })

      .addCase(addProduct.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage = "Product added successfully";

        const product = payload;

        if (product) {
          if (!Array.isArray(state.products)) {
            state.products = [];
          }
          state.products.push({
            id: product.product_id,
            merchantId: product.merchant_id,
            productName: product.product_name,
            shortProductName: product.short_product_name,
            categoryId: product.category_id,
            subCategoryId: product.sub_category_id,
            productDescription: product.product_description,
            productPrice: product.product_price,
            productModel: product.product_model,
            productColor: product.product_color,
            productPicture: product.product_picture.picture1,
            expiryDate: product.expiry_date,
            vat: product.vat,
            productDiscountPercentage: product.product_discount_percentage,
            productQuantity: product.product_quantity,
          });
        } else {
          state.errorMessage = "Product information is missing.";
        }
      })
      .addCase(fetchMerchantProducts.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchMerchantProducts.fulfilled, (state, action) => {
        state.loader = false;
        state.products = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchMerchantProducts.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchProductById.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchProductById.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.productDetails = payload;
      })
      .addCase(fetchProductById.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload;
      })
      .addCase(updateProduct.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateProduct.rejected, (state, { payload }) => {
        state.loader = false;
        state.updateErrorMessage = "An error occurred, can't update product.";
      })
      .addCase(updateProduct.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.product = payload.product;
        state.updateSuccessMessage = "Product updated successfully";
      })

      .addCase(deleteProduct.fulfilled, (state, { payload }) => {
        const { productId, message } = payload;

        if (Array.isArray(state.products) && productId) {
          state.products = state.products.filter(
            (product) => product.product_id !== productId
          );
          state.deleteSuccessMessage =
            message || "Product deleted successfully";
        } else {
          state.deleteErrorMessage = "Product ID missing in delete response.";
        }
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = "failed";
        state.deleteErrorMessage = action.payload;
      });
  },
});

export const { messageClear } = productReducer.actions;
export default productReducer.reducer;
