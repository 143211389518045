// import { configureStore } from "@reduxjs/toolkit";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import rootReducer from "./rootReducer";

// // Configure persist
// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: ["auth", "register"], // Only persist the auth reducer
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   // reducer: rootReducer,
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) => {
//     return getDefaultMiddleware({
//       serializableCheck: false,
//     });
//   },

//   devTools: true,
// });

// const persistor = persistStore(store);

// export { store, persistor };

// import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./rootReducer";

// // Configure store without persist
// const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) => {
//     return getDefaultMiddleware({
//       serializableCheck: true,
//     });
//   },
//   devTools: true,
// });

// export { store };

import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        serializableCheck: false,
      },
    }).concat(thunk),
  devTools: process.env.NODE_ENV !== "production",
});

export { store };

// import { configureStore } from "@reduxjs/toolkit";
// import { thunk } from "redux-thunk";
// import rootReducer from "./rootReducer";

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
// });

// export { store };

// import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./rootReducer";

// const store = configureStore({
//   reducer: rootReducer,
//   // You can directly use the default middleware without needing to import thunk separately
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
// });

// export { store };
