import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchMerchantDetails = createAsyncThunk(
  "auth/fetchMerchantDetails",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.post("/merchant_details", info, {
        withCredentials: true,
      });
      const { data } = response;
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const seller_login = createAsyncThunk(
  "auth/seller_login",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post("/merchant_login", info, {
        withCredentials: true,
      });

      if (data.status_code === 0) {
        return fulfillWithValue(data.merchantDetails);
      } else if (data.status_code === 1) {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const seller_register = createAsyncThunk(
  "auth/seller_register",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post("merchant_pin/1", info, {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const authReducer = createSlice({
  name: "auth",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    userInfo: "",
    merchantInfo: null,
    merchantDetails: [],
  },
  reducers: {
    messageClear: (state, _) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
    logout: (state) => {
      state.merchantInfo = null;
      state.successMessage = "";
      state.errorMessage = "";
      sessionStorage.removeItem("merchant");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantDetails.pending, (state, { payload }) => {
        state.loader = true;
      })
      .addCase(fetchMerchantDetails.rejected, (state, { payload }) => {
        if (payload.status_code === 1) {
          state.loader = false;
          state.errorMessage = payload.message;
        }
      })
      .addCase(fetchMerchantDetails.fulfilled, (state, { payload }) => {
        if (payload.status_code === 0) {
          state.loader = false;
          state.successMessage = payload.message;
          state.merchantDetails.push(payload.result);
        }
      })

      .addCase(seller_register.pending, (state, { payload }) => {
        state.loader = true;
      })

      .addCase(seller_register.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.error;
      })

      .addCase(seller_register.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage = payload.message;
      })
      .addCase(seller_login.pending, (state) => {
        state.loader = true;
      })
      .addCase(seller_login.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload;
      })
      .addCase(seller_login.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.successMessage = "Login successful";
        state.merchantDetails = payload;
        sessionStorage.setItem(
          "merchant",
          JSON.stringify(state.merchantDetails)
        );
      });
  },
});

export const { messageClear, logout } = authReducer.actions;
export default authReducer.reducer;
