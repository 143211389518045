// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import api from "../../api/api";

// const merchantReducer = createSlice({
//   name: "allmerchants",
//   initialState: {
//     merchants: [],
//     status: "idle",
//     success: null,
//     error: null,
//   },
//   reducers: {
//     messageClear: (state) => {
//       state.error = "";
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//   },
// });

// export const { messageClear } = merchantReducer.actions;
// export default merchantReducer.reducer;

// merchantReducer.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchMerchantStats = createAsyncThunk(
  "merchant/fetchMerchantStats",
  async (phoneNumber, { rejectWithValue }) => {
    console.log(phoneNumber);
    try {
      const response = await api.get(`/merchant_stats/${phoneNumber}`);
      if (response.data.status_code === 0) {
        return response.data.data;
      }
      return rejectWithValue(response.data.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const merchantReducer = createSlice({
  name: "merchant",
  initialState: {
    stats: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMerchantStats.fulfilled, (state, action) => {
        state.loading = false;
        state.stats = action.payload;
      })
      .addCase(fetchMerchantStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default merchantReducer.reducer;
