import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchMerchantDetails = createAsyncThunk(
  "register/fetchMerchantDetails",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.post("/merchant_details", info, {
        withCredentials: true,
      });
      const { data } = response;

      if (data.status_code === 0) {
        return fulfillWithValue({
          ...data,
          zippy_wallet_number: info.zippy_wallet_number,
        });
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const merchantRegister = createAsyncThunk(
  "register/merchantRegister",
  async (merchantDetails, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.post("/register_merchant", merchantDetails, {
        withCredentials: true,
      });
      const { data } = response;

      if (data.status_code === 0) {
        return fulfillWithValue({
          ...data,
          merchantId: data.data.merchant_id,
        });
      } else if (data.status_code === 1) {
        return fulfillWithValue({
          ...data,
          merchantId: data.merchantId,
        });
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPin = createAsyncThunk(
  "register/createPin",
  async ({ pin, merchantId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await api.post(
        `/merchant_pin/${merchantId}`,
        { pin },
        {
          withCredentials: true,
        }
      );
      const { data } = response;

      if (data.status_code === 0) {
        return fulfillWithValue(data);
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerReducer = createSlice({
  name: "register",
  initialState: {
    registerSuccessMessage: "",
    registerErrorMessage: "",
    detailsSuccessMessage: "",
    detailsErrorMessage: "",
    pinSuccessMessage: "",
    pinErrorMessage: "",
    loader: false,
    merchantDetails: {
      names: "",
      gender: "",
      email: "",
      address: "",
      zippy_wallet_number: "",
      bvn: "",
      providus_account_no: "",
      status: 1,
    },
    merchantID: "",
  },
  reducers: {
    setMerchantDetails: (state, action) => {
      state.merchantDetails = action.payload;
    },
    messageClear: (state) => {
      state.registerSuccessMessage = "";
      state.registerErrorMessage = "";
      state.detailsSuccessMessage = "";
      state.detailsErrorMessage = "";
      state.pinSuccessMessage = "";
      state.pinErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchantDetails.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchMerchantDetails.rejected, (state, { payload }) => {
        state.loader = false;
        state.detailsErrorMessage = payload.message || "An error occurred";
      })

      .addCase(fetchMerchantDetails.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.detailsSuccessMessage = payload.message;

        if (Array.isArray(payload.result) && payload.result.length > 0) {
          const merchantInfo = payload.result[0];
          state.merchantDetails = {
            names: merchantInfo.names,
            gender: merchantInfo.gender,
            email: merchantInfo.email,
            address: merchantInfo.address,
            bvn: merchantInfo.bvn,
            providus_account_no: merchantInfo.account_no,
            zippy_wallet_number: payload.zippy_wallet_number,
            status: 0,
          };
        } else {
          state.detailsErrorMessage = "No merchant details found.";
        }
      })

      .addCase(merchantRegister.pending, (state) => {
        state.loader = true;
      })
      .addCase(merchantRegister.rejected, (state, { payload }) => {
        state.loader = false;
        state.registerErrorMessage = payload.message;
      })

      .addCase(merchantRegister.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.registerSuccessMessage = payload.message;
        if (payload.status_code === 0 && payload.data) {
          state.merchantID = payload.data.merchant_id;
        } else if (payload.status_code === 1 && payload.merchantId) {
          state.merchantID = payload.merchantId;
        } else {
          console.error("Merchant details are not available in the payload.");
          state.registerErrorMessage =
            "Merchant details not found after registration.";
        }
        if (!state.merchantDetails.zippy_wallet_number) {
          state.merchantDetails.zippy_wallet_number =
            payload.zippy_wallet_number || "";
        }
      })
      .addCase(createPin.pending, (state) => {
        state.loader = true;
      })
      .addCase(createPin.rejected, (state, { payload }) => {
        state.loader = false;
        state.pinErrorMessage = payload.message;
      })
      .addCase(createPin.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.pinSuccessMessage = payload.message;
      });
  },
});

export const { messageClear } = registerReducer.actions;
export default registerReducer.reducer;
