import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchSubcategoriesByCategory = createAsyncThunk(
  "subcategory/fetchSubcategoriesByCategory",
  async (categoryId, { rejectWithValue }) => {
    try {
      const { data } = await api.get(
        `/categories/subcategories/${categoryId}`,
        {
          withCredentials: true,
        }
      );

      if (data.status_code === 0) {
        return { categoryId, subcategories: data.subcategories };
      } else {
        return rejectWithValue(data.message || "Something went wrong");
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Network error");
    }
  }
);

export const fetchAllSubcategories = createAsyncThunk(
  "category/fetchAllSubcategories",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("/categories/all_subcategories", {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const subcategoryReducer = createSlice({
  name: "subcategory",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    subcategories: [],
    subcategoriesByCategory: [],
  },
  reducers: {
    clearSubcategories: (state) => {
      state.subcategoriesByCategory = [];
    },
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubcategoriesByCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchSubcategoriesByCategory.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (Array.isArray(payload.subcategories)) {
          const updatedSubcategories = payload.subcategories.map((sub) => ({
            ...sub,
            category_id: payload.categoryId,
          }));

          state.subcategoriesByCategory = [
            ...state.subcategoriesByCategory.filter(
              (sub) => sub.category_id !== payload.categoryId
            ),
            ...updatedSubcategories,
          ];
        } else {
          console.error(
            "Expected subcategories to be an array, but got:",
            payload.subcategories
          );
        }
      })

      .addCase(fetchSubcategoriesByCategory.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload;
      })
      .addCase(fetchAllSubcategories.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchAllSubcategories.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchAllSubcategories.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.subcategories = payload;
      });
  },
});

export const { clearSubcategories, messageClear } = subcategoryReducer.actions;
export default subcategoryReducer.reducer;
